const getIsPromoDate = () => {
  const currentDate = new Date();
  const startDate = new Date('2024-08-26');
  const endDate = new Date('2024-09-14');

  return currentDate >= startDate && currentDate <= endDate;
};

export default {
  getIsPromoDate,
};
