class Paths {
  paths = {};

  subfolder = process.env.GATSBY_SUBFOLDER
    ? `/${process.env.GATSBY_SUBFOLDER}/`
    : '/';

  constructor() {
    if (this.subfolder !== '/') {
      // Adding home path to landing without subfolder crashes the application
      this.addPath('HOME', {
        path: this.subfolder,
        component: this.HOME,
      });
    }
  }

  getPaths() {
    return Object.fromEntries(
      // Stripping anchors - "pages" without components
      Object.entries(this.paths).filter(([, { component }]) => !!component)
    );
  }

  addPath(name, path) {
    this.paths[name] = path;
  }

  // Add here all pages that exists in the application
  HOME = 'src/pages/index.js';

  PRIVACY_POLICY = 'src/pages/privacy-policy.js';

  TOS = 'src/pages/tos.js';

  ONLINE_EDUCATION = 'src/pages/OnlineEducation/index.js';

  KOKOLINGO_GIFT = 'src/pages/KokolingoGift/index.js';

  FAQ = 'src/pages/FAQPage/index.js';

  SPEECH_THERAPY_ASSESSMENT = 'src/pages/SpeechTherapyAssessment/index.js';

  TELETHERAPY = 'src/pages/Teletherapy/index.js';

  ACADEMY = 'src/pages/Academy/index.js';

  ASHA = 'src/pages/Asha/index.js';

  GAME = 'src/pages/Game/index.js';

  PARTNERS = 'src/pages/PartnersPage/index.js';

  MEDIA = 'src/pages/MediaPage/index.js';

  INSTITUTIONS = 'src/pages/Institutions/index.js';

  // Newest blog page is defined in the file bellow
  EDUCATIONAL_CORNER = 'src/pages/EducationalCorner/index.js';

  AUTISM_SPECTRUM_DISORDER =
    'src/pages/EducationalCorner/AutismSpectrumDisorder/index.js';

  BIG_ISSUE_YOU_HAVENT_HEARD_ABOUT =
    'src/pages/EducationalCorner/BigIssueYouHaventHeardAbout/index.js';

  BILINGUALISM_AT_CHILDREN =
    'src/pages/EducationalCorner/BilingualismAtChildren/index.js';

  CHILDHOOD_APRAXIA_OF_SPEECH =
    'src/pages/EducationalCorner/ChildhoodApraxiaOfSpeech/index.js';

  CHILDREN_MOTIVATION_FOR_KOKOLINGO =
    'src/pages/EducationalCorner/ChildrenMotivationForKokolingo/index.js';

  COGNITIVE_DEVELOPMENT_OF_THE_GAME =
    'src/pages/EducationalCorner/CognitiveDevelopmentOfTheGame/index.js';

  DISTANCE_SPEECH_THERAPY =
    'src/pages/EducationalCorner/DistanceSpeechTherapy/index.js';

  EARLY_INTERVENTION_SPEECH_DEVELOPMENT =
    'src/pages/EducationalCorner/EarlyInterventionSpeechDevelopment/index.js';

  ENABLE_GREAT_SCHOOL_START =
    'src/pages/EducationalCorner/EnableGreatSchoolStart/index.js';

  ENCOURAGING_CORRECT_PRONUNCIATION =
    'src/pages/EducationalCorner/EncouragingCorrectPronunciation/index.js';

  ENCOURAGING_LANGUAGE_UNDERSTANDING =
    'src/pages/EducationalCorner/EncouragingLanguageUnderstanding/index.js';

  ENCOURAGING_SPEECH_EXPRESSIONS =
    'src/pages/EducationalCorner/EncouragingSpeechExpressions/index.js';

  ENVIRONMENTAL_IMPACT_ON_SPEECH =
    'src/pages/EducationalCorner/EnvironmentalImpactOnSpeech/index.js';

  GAME_AS_A_KEY_TO_CHILDS_GROWTH =
    'src/pages/EducationalCorner/GameAsAKeyToChildsGrowth/index.js';

  HOW_TO_FIND_TIME_FOR_KIDS =
    'src/pages/EducationalCorner/HowToFindTimeForKids/index.js';

  IMPORTANCE_OF_SPEECH_THERAPY_AT_HOME =
    'src/pages/EducationalCorner/ImportanceOfSpeechTherapyAtHome/index.js';

  KOKOLINGO_FOR_MOBILES =
    'src/pages/EducationalCorner/KokolingoForMobiles/index.js';

  KOKOLINGO_IN_OSIJEK =
    'src/pages/EducationalCorner/KokolingoInOsijek/index.js';

  LANGUAGE_DEVELOPMENT =
    'src/pages/EducationalCorner/LanguageDevelopment/index.js';

  LIQUID_AND_NON_LIQUID_SPEECH =
    'src/pages/EducationalCorner/LiquidAndNonLiquidSpeech/index.js';

  MOTOR_AND_HEARING_PREDICTIONS =
    'src/pages/EducationalCorner/MotorAndHearingPredictions/index.js';

  NO_THEORY_JUST_PRAXIS =
    'src/pages/EducationalCorner/NoTheoryJustPraxis/index.js';

  ONLINE_SPEECH_THERAPY_EXERCISES =
    'src/pages/EducationalCorner/OnlineSpeechTherapyExercises/index.js';

  PRACTICE_VOICE_R = 'src/pages/EducationalCorner/PracticeVoiceR/index.js';

  PRACTICE_VOICE_Š = 'src/pages/EducationalCorner/PracticeVoiceŠ/index.js';

  PREREQUISITES_OF_READING_AND_WRITING =
    'src/pages/EducationalCorner/PrerequisitesOfReadingAndWriting/index.js';

  PRESCHOOLER_AND_SPEECH_THERAPY =
    'src/pages/EducationalCorner/PreschoolerAndSpeechTherapy/index.js';

  READING_AND_WRITING =
    'src/pages/EducationalCorner/ReadingAndWriting/index.js';

  SPEECH_DEVELOPMENT = 'src/pages/EducationalCorner/SpeechDevelopment/index.js';

  SPEECH_DISORDER = 'src/pages/EducationalCorner/SpeechDisorder/index.js';

  SPEECH_DISORDER_THERAPY =
    'src/pages/EducationalCorner/SpeechDisorderTherapy/index.js';

  SPEECH_THERAPISTS_AND_KOKOLINGO =
    'src/pages/EducationalCorner/SpeechTherapistsAndKokolingo/index.js';

  TELETHERAPY_IN_EARLY_INTERVENTION =
    'src/pages/EducationalCorner/TeletherapyInEarlyIntervention/index.js';

  VOICES_K_AND_G = 'src/pages/EducationalCorner/VoicesKAndG/index.js';

  DYSLALIA = 'src/pages/EducationalCorner/Dyslalia/index.js';

  // Anchor goes without component path as they depend on other page path
  // gatsby-plugin-dynamic-routes doesn't know how to handle multiple paths with the same components
  PARENTS_ANCHOR;

  FACILITIES_ANCHOR;
}

module.exports = new Proxy(new Paths(), {
  get(target, prop) {
    const path = target.paths[prop];

    if (!process.env.NODE && path && typeof path.path === 'string') {
      return path.path;
    }

    return target[prop];
  },
  set(target, prop, path) {
    if (prop in target) {
      target.addPath(prop, {
        path: `${target.subfolder}${path}`,
        component: target[prop],
      });
    }
  },
});
