const HOME = process.env.GATSBY_APP_URL;
const REGISTER = process.env.GATSBY_APP_URL;
const APP_LOGIN = `${HOME}/login`;
const API_REGISTER = `${process.env.GATSBY_SERVER_URL}/auth/register/client`;
const API_REGISTER_NO_PW = `${process.env.GATSBY_SERVER_URL}/auth/register/client-no-pw`;
const APP_PAYMENT = `/settings/subscriptions`;
const APP_SUBSCRIPTION_STATUS = `/settings/subscription-status`;
const TELETHERAPY_FORM_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSd3538HkRZ438iWPclG6_B2IOSF6uGpzE6KPTuKiBLTNW9R9w/viewform';
const TELETHERAPY_PHONE_CALL_FORM_URL = 'https://www.bit.ly/logoped-poziv';
const ACADEMY_FORM_URL = 'https://forms.gle/huj7EzW9p9L7Ue5R6';

export default {
  HOME,
  REGISTER,
  APP_LOGIN,
  APP_PAYMENT,
  API_REGISTER,
  API_REGISTER_NO_PW,
  APP_SUBSCRIPTION_STATUS,
  TELETHERAPY_FORM_URL,
  TELETHERAPY_PHONE_CALL_FORM_URL,
  ACADEMY_FORM_URL,
};
